<template>
  <div>
    <div class="goodsAttributeEditTypeContainer">
      <!--    goodsDetailList #c35255-->
      <el-button size="mini" @click="attributeTypeStatus=1"
                 :style="attributeTypeStatus===1?'background:#c35255;color:#fff':'background:#fff'">
        {{ $fanyi('网站属性选择') }}
      </el-button>
      <el-button size="mini" @click="attributeTypeStatus=2"
                 :style="attributeTypeStatus===2?'background:#c35255;color:#fff':'background:#fff'">
        {{ $fanyi('人工编辑属性') }}
      </el-button>
    </div>
    <div class="AttributeListContainer" v-show="attributeTypeStatus===1">
      <div v-for="(item,index) in goodsDetailList" :key="index" style="display: flex"
           class="goodsDetailContainer">
        <div class="keyContainer">{{ item.keyT }}：</div>
        <div class="valueAttributeListContainer">
          <div v-for="(items,valueIndex) in item.valueC" :key="valueIndex"
               :class="item.active===valueIndex?'fontSize14 color333 active':'fontSize14 color333'"
               @click="item.active=valueIndex;$forceUpdate();">{{ items.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="artificialAttributeListContainer" v-show="attributeTypeStatus===2">
      <div class="artificialAttributeTitle flexAndCenter">
        <div>{{ $fanyi('属性名称') }}</div>
        <div>{{ $fanyi('具体属性值') }}</div>
      </div>
      <div>
        <div v-for="(item,index) in artificialAttributeEditList" :key="index"
             class="artificialAttributeEditContainer flexAndCenter">
          <div class="artificialAttributeEditLeftContainer flexAndCenter">
            <el-input v-model="item.key" style="width: 200px;margin-right: 15px" size="small"></el-input>
            <el-input v-model="item.value" style="width: 200px" size="small"></el-input>
          </div>
          <div class="artificialAttributeEditRightContainer" style="margin-left: 10px;cursor: pointer"
               @click="artificialAttributeEditList.splice(index,1)">
            <img src="../../../../../../assets/7.png" alt="">
          </div>
        </div>
        <div class="addAttributeText fontSize14 flexAndCenter"
             @click="artificialAttributeEditList.push({key:'',value:''})"
             style="cursor: pointer">
          <img src="../../../../../../assets/5.png" alt="" style="width:16px;margin-right:7px">
          {{ $fanyi("添加属性") }}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" style="margin-top: 30px">
       <el-button @click="hideGoodsDetailDialogVisible">{{ $fanyi('取 消') }}</el-button>
       <el-button type="primary" @click="saveGoodsDetail">{{ $fanyi('保 存') }}</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "goodsAttributeEdit",
  props: {
    goodsDetailList: {
      type: Array
    },
    timer: {
      type: String
    }
  },
  data() {
    return {
      attributeTypeStatus: 1,
      artificialAttributeEditList: [{
        key: '',
        value: ''
      }, {
        key: '',
        value: ''
      }]
    }
  },
  methods: {
    //保存商品规格
    saveGoodsDetail() {
      let arr = [];
      this.artificialAttributeEditList.forEach((item, index) => {
        if (item.key === '' || item.value === '') {
          this.artificialAttributeEditList.splice(index, 1)
        }
      })
      this.attributeTypeStatus === 1 ? arr = this.goodsDetailList : arr = this.artificialAttributeEditList
      this.$emit("saveGoodsDetail", this.attributeTypeStatus, arr)
    },
    //关闭弹窗
    hideGoodsDetailDialogVisible() {
      this.$emit("hideGoodsDetailDialogVisible", this.attributeTypeStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/orderDetails.scss";

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 120px;
    height: 34px;
    border-radius: 8px;
    padding: 0;
    font-size: 14px;
  }

  button:first-child {
    border: 1px solid #B4272B;
    color: #B4272B;
  }
}

.AttributeListContainer {
  height: 600px;
  overflow: scroll;
}
</style>
