<template>
  <div>
    <div :class="cart ? 'main' : ' main hei'">
      <div class="type">
        <button>
          <!-- 购物车 -->{{ $fanyi("商品库") }}
        </button>
        <div>（{{ cartCount }}）</div>
      </div>
      <div class="head" :style="$route.name == 'cart' ? 'top: 107px;' : ''">
        <ul>
          <li class="designation">{{ $fanyi("番号") }}</li>
          <li class="designation">{{ $fanyi("选择") }}</li>
          <li class="managementDesignation">{{ $fanyi("管理番号") }}</li>
          <li class="goodsName">{{ $fanyi("商品标题") }}</li>
          <li class="goodsSpecification">{{ $fanyi("商品属性") }}</li>
          <li class="price">{{ $fanyi("单价(元)") }}</li>
          <li class="option">{{ $fanyi("オプション") }}</li>
          <li class="option">{{ $fanyi("标签设置") }}</li>
          <li class="remarks">{{ $fanyi("备注") }}</li>
        </ul>
      </div>
      <!-- 购物车有商品 -->
      <div class="lists" v-if="lists != false">
        <!--        <div class="item" v-for="(item, index) in lists" :key="index">-->
        <!--          &lt;!&ndash; 单个商品 &ndash;&gt;-->
        <div class="list">
          <ul v-for="(listItem, listIndex) in lists" :key="listIndex">
            <li class="designation">{{ listItem.count }}</li>
            <li class="designation flexAndCenter" style="justify-content: center">
              <input type="checkbox" v-model="listItem.checked"
                     @change="oddChoice(listIndex)"/>
            </li>
            <li class="managementDesignation">{{ listItem.for_sn }}</li>
            <li class="goodsName flex">
              <a href="javascript:;"
                 @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)">
                <el-popover placement="right" trigger="hover">
                  <img :src="listItem.img_url" style="width: 300px; height: 300px"/>
                  <img :src="listItem.img_url" style="width: 75px; height: 75px" slot="reference"/>
                </el-popover>
              </a>
              <div>{{ listItem.goods_title }}</div>
            </li>
            <li class="goodsSpecification">
              <el-popover>
                <div class="DetailAll">
                  <p v-for="(detailItem, detailIndex) in listItem.detail"
                     :key="detailIndex"
                     style="font-weight: 400;color: #999"
                     :title="detailItem.key + ':' + detailItem.value">
                    {{ detailItem.key }}:{{ detailItem.value }}
                  </p>
                </div>
                <div slot="reference" class="DetailAll" style="cursor: pointer">
                  <p v-for="(detailItem, detailIndex) in listItem.detail"
                     :key="detailIndex"
                     style="font-weight: 400;color: #999"
                     :title="detailItem.key + ':' + detailItem.value">
                    {{ detailItem.key }}:{{ detailItem.value }}
                  </p>
                </div>
              </el-popover>
            </li>
            <li class="price">{{ listItem.price }}</li>
            <li class="option">
              <el-popover trigger="hover">
                <div style="width: 300px" class="u-line">
                  <div v-for="(optionItem,optionIndex) in listItem.option" :key="optionIndex"
                       style="text-align: left;width: 300px">
                    <div v-if="optionItem.num>0&&optionItem.checked===true">
                      {{ optionIndex + 1 }}） {{ optionItem.name_translate }}（{{ optionItem.price }}
                      {{ $fanyi("元") }}/{{ optionItem.unit }} * {{ optionItem.num }}）
                    </div>
                  </div>
                </div>
                <div class="u-line" slot="reference">
                  <div v-for="(optionItem,optionIndex) in listItem.option" :key="optionIndex"
                       style="text-align: left;width: 160px">
                    <div v-if="optionItem.num>0&&optionItem.checked===true">
                      {{ optionIndex + 1 }}. {{ optionItem.name_translate }} *{{ optionItem.num }}
                    </div>
                  </div>
                </div>
              </el-popover>
            </li>
            <li class="option flex" style="justify-content: center">
              <div v-for="(tagItem,tagIndex) in listItem.inventory_tag" :key="tagIndex" class="inventoryTagContainer">
                <div>{{ $fanyi('类别') }}：{{ tagItem.type_translate }}</div>
                <div>{{ $fanyi('标签号') }}：{{ tagItem.no }}</div>
                <div>{{ $fanyi('商品码') }}：{{ tagItem.goods_no }}</div>
              </div>
            </li>
            <li class="remarks flexAndCenter" style="justify-content: center">
              <el-input type="textarea" disabled style="width: 180px;" resize="none" v-model="listItem.note"/>
            </li>
            <!--              note-->
          </ul>
        </div>
        <!--        </div>-->
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../../../../../assets/img/gwcc.png" alt=""/>
          <div class="nav">
            <p>{{ $fanyi("商品库为空") }}</p>
            <p>
              <a href="javascript:;" @click="$fun.routerToPage('/')">{{ $fanyi("回到顶部") }} |</a>
              <a href="javascript:;" @click="$fun.routerToPage('/collection')">{{ $fanyi("你的最爱") }} |</a>
              <a to="#" @click="$fun.routerToPage('/user/commodity')">{{ $fanyi("资料库") }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="foot" :class="{ order: this.$route.name != 'cart' }">
        <div class="flexAndCenter">
          <input type="checkbox" v-model="checked" @change="allChoice"/>
          <b class="quanXuan">{{ $fanyi("全选") }}</b>
        </div>
        <div class="flexAndCenter">
          <div class="flexAndCenter selectGoodsNum">
            <div>{{ $fanyi("選択中の商品") }}</div>
            <div class="selectGoodsNumTotal">{{ total }}</div>
            <div>{{ $fanyi("点") }}</div>
          </div>
          <button :disabled="idArr.length == 0" :class="{ btn: idArr.length > 0 }" @click="jieSuan">
            {{ $fanyi("追加") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../../../components/public/Dialog";

export default {
  props: {
    ids: {//当前订单页面的id集合
      type: String
    },
    timer: {//刷新子组件
      type: String
    }
  },
  data() {
    return {
      checked: false, //全选
      type: 0, // 类型
      cartCount: 0,
      isTotal: 0, // 选中的商品数
      lists: [], // 数据
      idArr: [], // 选中的ID
      cart: true,
      total: 0,
    };
  },
  computed: {},
  components: {
    Dialog,
  },
  mounted() {
    this.getCartList();
    if (this.$route.name == "OrderDetails") {
      this.cart = false;
    }
  },
  methods: {
    toHome() {
      sessionStorage.setItem("toHot", true);
      this.$fun.routerToPage("/");
    },
    // 获取数据
    getCartList() {
      this.$api.orderAddInventoryGoods({
        overlook_inventory_ids: this.ids
      }).then((res) => {
        if (res.code != 0) {
          this.lists = [];
          return false;
        }
        let count = 0;
        let arr = [];
        res.data.forEach((item) => {
          item.goods.forEach((goodsItem) => {
            this.cartCount++;
            count++;
            goodsItem.count = count;
            goodsItem.checked = false;
            if (typeof goodsItem.detail === 'string') {
              goodsItem.detail = JSON.parse(goodsItem.detail);
            }
            arr.push(goodsItem)
          })
        })
        this.lists = arr;
      });
    },
    // 单选
    oddChoice(i) {
      if (this.lists[i].checked === true) {
        this.total++;
        this.idArr.push(this.lists[i].id)
      } else {
        this.total--;
        for (let i = this.idArr.length - 1; i >= 0; i--) {
          if (this.lists[i].id === this.idArr[i]) {
            this.idArr.splice(i, 1)
            break;
          }
        }
      }
      this.isCheckedAll();
      this.$forceUpdate();
    },
    // 全选
    allChoice() {
      this.total = 0;
      this.idArr = [];
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true;
          this.total++;
          this.idArr.push(item.id)
        });
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 结算
    jieSuan() {
      let newArr = this.ids.split(",");
      if (this.idArr.length + newArr.length > 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      let arr = [];
      this.lists.forEach((item) => {
        if (item.checked === true) {
          arr.push(item)
        }
      })
      this.$emit("inventoryAddGoodsToOrder", arr);
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../../../../../css/mixin";

.main {
  width: 1402px;
  margin: 10px 30px 80px;
  background: #fff;
  border-radius: 6px;

  /deep/ input[type="checkbox"]:checked {
    background: #4A91E9;
  }

  &.hei {
    margin-top: 20px;
    height: 600px;
    overflow: auto;
  }

  input {
    vertical-align: middle;
  }

  .type {
    display: flex;
    align-items: center;

    button {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
      background-color: transparent;
      line-height: 10px;
      letter-spacing: 0;
      margin: 26px 0 26px 25px;
    }

    div {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
    }
  }

  input {
    width: 16px;
    height: 16px;
  }

  .designation {
    width: 68px;
    min-width: 68px;
  }

  .managementDesignation {
    width: 122px;
    min-width: 122px;
  }

  .goodsSpecification {
    width: 150px;
    min-width: 150px;
  }

  .goodsName {
    width: 280px;
    min-width: 280px;

    img {
      width: 75px;
      height: 75px;
      margin-top: 13px;
    }

    div {
      margin-left: 9px;
      width: 155px;
      line-height: 20px;
      text-align: left;
      margin-top: 13px;
    }
  }

  .price {
    width: 110px;
    min-width: 110px;
  }

  .option {
    width: 200px;
    min-width: 200px;

    .u-line {
      margin-top: 13px;
      height: 61px;
      width: 160px;
      overflow: hidden;
      line-height: 20px;
      color: #333;
      font-weight: 400;
    }

    .inventoryTagContainer {
      line-height: 20px;
      margin-top: 12px;

      div {
        text-align: left;
      }
    }
  }

  .remarks {
    width: 204px;
    min-width: 204px;

    /deep/ .el-textarea__inner {
      width: 180px;
      height: 75px;
      background: #F9F9F9;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }
  }

  .head {
    // top的设置在行内样式里
    height: 46px;
    background: #eee;
    position: sticky;
    z-index: 4;

    ul {
      display: flex;
      line-height: 46px;
      font-size: 14px;


      .goods {
        flex: 0 0 240px;
      }


      li {
        text-align: center;

        input {
          margin-left: 10px;
        }

        &.detail {
          flex: 0 0 200px;
        }
      }
    }
  }

  .lists {
    padding-bottom: 100px;
    height: 450px;
    overflow: scroll;
  }

  //.item {
  //  h4 {
  //    font-weight: bold;
  //    font-size: 16px;
  //    line-height: 64px;
  //    display: flex;
  //    align-items: center;
  //    color: #333333;
  //
  //    input {
  //      margin: 0 20px;
  //    }
  //
  //    a {
  //      color: #333;
  //
  //      &:hover {
  //        color: #999;
  //      }
  //    }
  //
  //    img {
  //      margin-left: 5px;
  //      vertical-align: middle;
  //      float: none;
  //    }
  //  }

  .list {
    .fg {
      width: 1360px;
      height: 10px;
      background: transparent;
      margin: 0 auto;
    }

    ul:hover {
      background: #FAF2F2;
    }

    ul {
      min-height: 100px;
      display: flex;
      border-top: 1px solid #ededed;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .checkBox {
        input {
          margin-left: 20px;
        }
      }

      .goods {
        width: 240px;
        min-width: 240px;
        padding-right: 26px;

        .text {
          height: 110px;
          display: flex;
          flex-direction: column;

          > input {
            margin-top: 34px;
            padding-left: 10px;
          }
        }
      }

      .price {
        width: 200px;

        p {
          width: 200px;
          word-break: break-all;
        }
      }

      li {
        // width: 14.28%;

        .check {
          height: 80px;
          display: flex;
          align-items: center;
        }

        input {
        }


        &.detail {
          position: relative;
          //flex: 0 0 200px;
          width: 100px;

          height: 125px;
          min-width: 100px;
          box-sizing: border-box;
          border: 1px solid transparent;
        }

        &.selectDetail {
          border: 1px dashed #B4272B;
        }

        &.goods {
          position: relative;
          // > div {
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   text-align: left;
          //   font-size: 14px;
          //   color: #000000;
          //   line-height: 19px;
          //
          // }
        }

        a {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .bei {
          width: 220px;
          min-height: 26px;
          height: 26px;
          border: 1px solid #DFDFDF;
          border-radius: 3px;
        }

        span {
          font-size: 14px;
          color: #B4272B;
          font-weight: 600;
        }
      }

      .del {
        font-size: 14px;
        color: #333333;

        &:hover {
          color: #B4272B;
        }
      }

      .price {
        .price-box {
          .danjia {
            text-align: left;
          }

          p {
            text-align: center;
            padding: 0 10px;
          }

          padding-left: 15px;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }

  //}

  .cart {
    padding: 50px 0;
    text-align: center;
    overflow: hidden;

    > div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    img {
      margin: 0 auto;
    }

    .nav {
      margin: 50px 0 0 30px;

      p {
        font-size: 20px;
        line-height: 50px;
        text-align: center;

        a {
          color: $homePageSubjectColor;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    width: 1400px;
    height: 70px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
    border-radius: 10px 10px 0px 0px;
    position: sticky;
    bottom: -0px;
    left: 50%;
    // transform: translate(-50%);
    line-height: 50px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      margin-left: 26px;
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    b {
      margin-top: -1px;
      cursor: pointer;
      font-size: 14px;
      color: #333333;
      line-height: 19px;

      &.quanXuan {
        font-weight: 400;
      }

      &.del {
        font-size: 14px;
        font-weight: 400;
        color: $homePageSubjectColor;
        line-height: 19px;
        margin-left: 40px;
      }
    }

    .selectGoodsNum {
      margin-right: 68px;


      div:first-child, div:last-child {
        color: #333;
        font-weight: 400;
      }

      .selectGoodsNumTotal {
        color: #B4272B;
        margin: 0 6px;
        font-weight: bold;
      }
    }

    .total {
      margin: 0 0 0 auto;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-left: 0px;

      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;

        .price {
          color: #B4272B;
          font-weight: bold;
          font-size: 20px;
          max-width: 246px;
          // flex-direction: column;

          span {
            font-size: 24px;
          }
        }

        .RMBTotal {
          font-weight: 400;
          font-size: 14px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 700;
        // margin: 0 10px;
      }

      .goodsNum {
        margin: 0 0 0 250px;
        position: relative;
        line-height: 30px;
        display: flex;
        font-size: 14px;
        align-items: center;

        .goodsNumLeft, .goodsNumRight {
          font-size: 16px;
          font-weight: 400 !important;
          color: #333;
        }

        .goodsNumCenter {
          margin-right: 10px;
          font-size: 16px;
          font-weight: bold;
          color: #B4272B;
        }
      }
    }

    i {
      margin: 0 20px;
    }

    button {
      width: 200px;
      height: 70px;
      background: #B4272B;
      border-radius: 0 10px 0 0;
      font-size: 30px;
      font-weight: 400;
      color: #fff;
      line-height: 70px;
      /*   */
      cursor: not-allowed;
      text-align: center;
      margin-left: 68px;
    }

    .btn {
      cursor: pointer;
    }
  }

  .order {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  width: 320px;

  .goodsDetailAllOneBox {
    display: flex;
    margin-bottom: 10px;

    h1 {
      font-size: 16px;
      font-weight: bold;
      padding-top: 3px;
      margin-right: 16px;
      min-width: 40px;
    }

    .valueCBoxList {
      display: flex;
      flex-wrap: wrap;
      border: none;

      div {
        background: transparent;
        border: 1px solid #DFDFDF;
        padding: 5px 10px;
        font-size: 14px;
        margin-right: 16px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .active {
        color: #B4272B;
        border: 1px solid #B4272B;
      }
    }
  }

  .updateDetailOption {
    display: flex;
    align-items: center;
    margin-left: 65px;

    div {
      padding: 6px 16px;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }


    div:first-child {
      background: #B4272B;
      color: #fff;
      padding: 7px 16px;
      margin-right: 16px;
    }

    div:last-child {
      border: 1px solid #B4272B;
      color: #B4272B;
    }
  }
}

.DetailAll {
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  max-height: 90%;
  // border: solid;
  width: 100%;
  padding-top: 26px;
  padding-left: 14px;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: center;

  p {
    margin: 0 auto;
    // width: 190px;
    text-align: left;
  }

  .leftIcon, .checkLeftIcon {
    width: 60px;
    height: 26px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
  }

  .checkLeftIcon {
    background-color: #B4272B;
  }

  .leftIcon {
    background-color: #DFDFDF;
  }
}

.detail:hover {
  border: 1px dashed #DFDFDF !important;
}
</style>
