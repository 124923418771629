<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div :class="cart ? 'main' : ' main hei'">
      <div class="type">
        <button :class="{ isbtn: type == 0 }">
          {{ $fanyi("购物车") }}
        </button>
        <div>（{{ cartCount }}）</div>
      </div>
      <div class="head" :style="$route.name == 'cart' ? 'top: 107px;' : ''">
        <ul>
          <li class="chexk-box" style="font-size: 14px">
            <input type="checkbox" v-model="checked"
                   @change="allChoice"/>
            <span>{{ $fanyi("全选") }}</span>
          </li>
          <li class="goods" style="text-align: left">{{ $fanyi("产品") }}</li>
          <li class="detail" style="text-align: left">{{ $fanyi("详情") }}</li>
          <li style="text-align: left">{{ $fanyi("单价") }}</li>
          <li>{{ $fanyi("数量") }}</li>
          <li>{{ $fanyi("总价") }}</li>
        </ul>
      </div>
      <!-- 购物车有商品 -->
      <div class="lists" v-if="lists != false">
        <div class="item" v-for="(item, index) in lists" :key="index">
          <h4 class="cursorPointer">
            <input v-model="item.checked" type="checkbox" @change="shopAll(index)"/>
            <span class="cursorPointer" @click="shopGoodsPageJump(item)">{{ $fanyi("店铺名") }} ：{{
                item.shop_name
              }}</span>
            <!--            {{ $fanyi("店铺名") }} ：{{-->
            <!--              item.shop_type == 1688 ? 1688 : item.shop_type == 'taobao' ? '淘宝' : item.shop_type == 'tmall' ? '天猫' : '网易严选'-->
            <!--            }}-->
            <a href="javascript:;" @click="$fun.routerToPage('/')">{{ item.goods_title }}</a>
          </h4>
          <!-- 单个商品 -->
          <div class="list">
            <ul v-for="(listItem, listIndex) in item.goods" :key="listIndex">
              <!--              选择-->
              <li class="checkBox" style="padding-top: 24px;">
                <div class="check">
                  <input type="checkbox" v-model="listItem.checked"
                         @change="oddChoice(index)"/>
                </div>
                <!--                写真-->
                <div class="img">
                  <a href="javascript:;"
                     @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)">
                    <el-popover placement="right" trigger="hover">
                      <img :src="listItem.pic" style="width: 300px; height: 300px"/>
                      <img :src="listItem.pic" slot="reference"/>
                    </el-popover>
                  </a>
                </div>
              </li>
              <!--              商品标题-->
              <li class="goods" style="padding-top: 24px;">
                <div class="text">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <p>
                      <a href="javascript:;"
                         @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)">
                        {{ listItem.goods_title }}
                      </a>
                    </p>
                    <p :title="listItem.goods_title" slot="reference">
                      <a href="javascript:;"
                         @click="$fun.toCommodityDetails(listItem.goods_id,listItem.from_platform)"
                         style="font-weight: 400">{{ listItem.goods_title }}
                      </a>
                    </p>
                  </el-popover>
                  <input class="bei" type="text"
                         v-model="listItem.client_remark"
                         @blur="editCartRemark(listItem)"/>
                </div>
              </li>
              <!-- 商品规格详情 -->
              <li :class="listItem.checkLeftIcon===true?'detail selectDetail':'detail'" style="position: relative"
                  @mouseenter="mouseenter(index,listIndex)"
                  @mouseleave="mouseleave(index,listIndex)" @mousedown="mousedown(index,listIndex)">
                <div v-if="(listItem.leftIcon===true||listItem.leftIcon===false)&&listItem.showGoodsDetails===false">
                  <el-popover placement="bottom" trigger="hover">
                    <p v-for="(detailItem, detailIndex) in listItem.detail"
                       :key="detailIndex" style="font-weight: 400;color: #999">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>
                    <div slot="reference" class="DetailAll" style="cursor: pointer">
                      <div v-if="listItem.leftIcon===true" class="leftIcon fontSize14">{{ $fanyi("编辑") }}</div>
                      <div v-if="listItem.showGoodsDetails===true" class="checkLeftIcon fontSize14">
                        {{ $fanyi("编辑") }}
                      </div>
                      <p v-for="(detailItem, detailIndex) in listItem.detail"
                         :key="detailIndex" style="font-weight: 400;color: #999">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div v-if="listItem.showGoodsDetails===true">
                  <el-popover placement="bottom" trigger="manual" v-model="listItem.showGoodsDetails">
                    <div v-if="listItem.goodsInfo!=undefined" class="goodsDetailAll">
                      <div class="goodsDetailAllOneBox"
                           v-for="(detailItem, detailIndex) in listItem.goodsInfo.specification"
                           :key="detailIndex">
                        <h1 class="detailTitle">{{ detailItem.keyC }}:</h1>
                        <div class="valueCBoxList">
                          <div v-for="(valueCItem,valueCIndex) in detailItem.valueC"
                               :key="valueCIndex" :class="detailItem.active===valueCIndex?'active':''"
                               @click="updateGoodsDetailValue(detailItem,valueCIndex)">{{ valueCItem.name }}
                          </div>
                        </div>
                      </div>
                      <div class="updateDetailOption">
                        <div
                            @click="updateGoodsDetail(listItem);listItem.showGoodsDetails=false;listItem.checkLeftIcon=false;listItem.leftIcon=false;">
                          {{ $fanyi("确定") }}
                        </div>
                        <div
                            @click="cancelUpdateGoodsDetailValue(listItem)">
                          {{ $fanyi("取消") }}
                        </div>
                      </div>
                    </div>
                    <div slot="reference" class="DetailAll" style="cursor: pointer">
                      <div v-if="listItem.leftIcon===true" class="leftIcon fontSize14">{{ $fanyi("编辑") }}</div>
                      <div v-if="listItem.showGoodsDetails===true" class="checkLeftIcon fontSize14">
                        {{ $fanyi("编辑") }}
                      </div>
                      <p v-for="(detailItem, detailIndex) in listItem.detail"
                         :key="detailIndex" style="font-weight: 400;color: #999">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
              </li>
              <!--              商品单价-->
              <li class="price" style="padding-top: 24px;">
                <div class="price-box">
                  <!-- 单价 -->
                  <div class="danjia">
                    <b>
                      ￥{{ Number(listItem.price) }}
                    </b>
                    <div v-for="(priceRangesItem,priceRangesIndex) in listItem.priceRanges" :key="priceRangesIndex"
                         class="fontSize14 color333">
                      ≥{{ priceRangesItem.startQuantity }}件 ￥{{ priceRangesItem.price }}
                    </div>
                  </div>
                </div>
              </li>
              <!-- 选择数量 -->
              <li style="text-align: center;padding-top: 24px" class="setNum">
                <el-input-number
                    v-model="listItem.num"
                    :step="1"
                    step-strictly
                    size="mini"
                    :min="1"
                    @change="numAmend(index, listIndex, listItem)"
                ></el-input-number>
              </li>
              <!-- 总价 -->
              <li style="text-align: center;padding-top: 24px">
                <span>¥{{ (Number(listItem.price) * listItem.num).toFixed(2) }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../../../../../assets/img/gwcc.png" alt=""/>
          <div class="nav">
            <p>{{ $fanyi("购物车为空") }}</p>
            <p>
              <a href="javascript:;" @click="$fun.routerToPage('/')">{{ $fanyi("回到顶部") }} |</a>
              <a href="javascript:;" @click="$fun.routerToPage('/user/commodity')">
                {{ $fanyi("你的最爱") }} |
              </a>
              <a to="#" @click="$fun.routerToPage('/collection')">{{ $fanyi("资料库") }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="foot" :class="{ order: this.$route.name != 'cart' }">
        <input type="checkbox"
               v-model="checked"
               @change="allChoice"/>
        <b class="quanXuan">{{ $fanyi("全选") }}</b>
        <div class="total">
          <div class="goodsNum">
            <p>
              <span class="goodsNumLeft">{{ $fanyi("已选商品") }}： </span>
              <span class="goodsNumCenter">{{ isTotal }}</span>
              <span class="goodsNumRight">{{ $fanyi("件") }}</span>
            </p>
          </div>
        </div>
        <button :disabled="idArr.length == 0" :class="{ btn: idArr.length > 0 }"
                @click="jieSuan">
          {{ $fanyi("追加") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../../../components/public/Dialog";
import debounce from "@/utlis/debounce";

export default {
  props: {
    ids: {//当前订单页面的id集合
      type: String
    },
    timer: {//刷新子组件
      type: String
    }
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      checked: false, //全选
      type: 0, // 类型
      num: 0, //
      isTotal: 0, // 选中的商品数
      shopTotal: 0, // 选中的店铺
      totalPrice: 0, // 选中的商品总价
      lists: [], // 数据
      cartCount: 0,
      idArr: [], // 选中的ID
      cart: true,
      // 弹窗的属性
      config: {
        width: "480px",
        title: this.$fanyi("删除提示"),
        btnTxt: [this.$fanyi("删除(购物车)"), this.$fanyi("取消")],
      },
    };
  },
  computed: {},
  components: {
    Dialog,
  },
  mounted() {
    this.getCartList();
    if (this.$route.name == "OrderDetails") {
      this.cart = false;
    }
  },
  methods: {
    toHome() {
      sessionStorage.setItem("toHot", true);
      this.$fun.routerToPage("/");
    },
    // 获取数据
    getCartList() {
      this.cartCount = 0;
      this.$api.orderAddCartGoods({
        overlook_cart_ids: this.ids
      }).then((res) => {
        if (res.code != 0) {
          this.lists = [];
          return false;
        }
        res.data.forEach((item) => {
          item.goods.forEach((goodsItem) => {
            this.cartCount++;
            if (typeof goodsItem.detail === 'string') {
              goodsItem.detail = JSON.parse(goodsItem.detail);
            }
            goodsItem.leftIcon = false;
            goodsItem.checkLeftIcon = false;
            goodsItem.showGoodsDetails = false;
          })
        })
        this.lists = res.data;
      });
    },
    //取消更新商品选中的规格
    cancelUpdateGoodsDetailValue(listItem) {
      listItem.showGoodsDetails = false;
      listItem.checkLeftIcon = false;
      listItem.leftIcon = false;
      if (listItem.goodsInfo.specification > 0) {
        listItem.goodsInfo.specification.forEach((item) => {
          item.active = this.$fun.deepClone(item.oldActive);
        })
      }
    },
    //更新商品的规格
    updateGoodsDetail(item) {
      let newDetailList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      let pic = '';
      if (item.goodsInfo != undefined) {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem, index) => {
            newDetailList.push({
              key: specificationItem.keyC,
              value: specificationItem.valueC[specificationItem.active].name
            })
            if (specificationItem.valueC[specificationItem.active].picUrl != '') {
              pic = specificationItem.valueC[specificationItem.active].picUrl
            }
            str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
          })
          str = str.substr(0, str.length - 2);
        }
        if (item.goodsInfo.goodsInventory.length > 0) {
          item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
            if (str === goodsInventoryItems.keyC) {
              sku_id = goodsInventoryItems.valueC[0].skuId;
              spec_id = goodsInventoryItems.valueC[0].specId;
            }
          });
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
      } else {
        sku_id = item.sku_id;
        spec_id = item.spec_id;
        this.uploadGoodsDetailsInfo(item);
      }
      if (pic !== '') {
        item.pic = pic
      }
      let datas = {
        num: item.num,
        id: item.id,
        price: item.price,
        pic: item.pic,
        detail: JSON.stringify(newDetailList),
        client_remark: item.client_remark,
        sku_id: sku_id,
        spec_id: spec_id,
      };
      this.$api.goodsCartEdit(datas).then((res) => {
      });
    },
    //更新商品选中的规格
    updateGoodsDetailValue(detailItem, valueCIndex) {
      detailItem.active = valueCIndex;
      this.$forceUpdate();
    },
    shopGoodsPageJump(item) {
      let href = `/shopGoods?shopId=${item.shop_id}&shopName=${item.shop_name}&shopType=${item.shop_type}`;
      window.open(href, "_blank");
    },
    // 整理数据
    tidyData() {
      this.lists.forEach((item) => {
        item.goods.forEach((item1) => {
          // 商品总数
          item1.checked = false; // 商品选择
          item1.univalence = 0; // 显示单价
          item1.total = 0;
          if (item1.goodsInfo.goodsInventory.length > 0 && item1.goodsInfo.specification.length - 1) {
            for (let i = 0; i < item1.goodsInfo.goodsInventory.length; i++) {
              for (let k = 0; k < item1.goodsInfo.specification.length; k++) {
                item1.detail.forEach((detailItem) => {
                  for (let j = 0; j < item1.goodsInfo.specification[k].valueC.length; j++) {
                    if (detailItem.key === item1.goodsInfo.specification[k].keyC && detailItem.value === item1.goodsInfo.specification[k].valueC[j].name) {
                      item1.goodsInfo.specification[k].active = j;
                    }
                  }
                })
              }
            }
          } else {
            item1.total = (Number(item1.price) * item1.num).toFixed(2);
          }
        });
      });
      this.subtotal();
    },
    // 修改数量
    numAmend(i, index, item) {
      debounce(() => {
        if (item.num == undefined) {
          item.num = 1;
          this.$forceUpdate();
        }
        //   数量不能小于最小数
        if (item.num < 1) {
          this.lists[i].list[index].num = item.price_range[0].startQuantity;
          return this.$message.error(this.$fanyi("数量不能小于最小数"));
        }
        // this.subtotal();
        let newDetailList = [];
        let str = '';
        let sku_id = '';
        let spec_id = '';
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem, index) => {
              newDetailList.push({
                key: specificationItem.keyC,
                value: specificationItem.valueC[specificationItem.active].name
              })
              str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
            })
            str = str.substr(0, str.length - 2);
          }
          if (item.goodsInfo.goodsInventory.length > 0) {
            item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
              if (str === goodsInventoryItems.keyC) {
                sku_id = goodsInventoryItems.valueC[0].skuId;
                spec_id = goodsInventoryItems.valueC[0].specId;
              }
            });
          } else {
            sku_id = item.sku_id;
            spec_id = item.spec_id;
          }
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
        let datas = {
          num: item.num,
          id: item.id,
          price: item.price,
          detail: JSON.stringify(item.detail),
          pic: item.pic,
          sku_id: sku_id,
          spec_id: spec_id,
          client_remark: item.client_remark
        };
        this.$forceUpdate();
        this.$api.goodsCartEdit(datas).then((res) => {
          if (res.code != 0) return;
          if (item.goodsInfo != undefined) {
            let str = '';
            item.detail.forEach((items) => {
              str += items.value + '㊖㊎'
            })
            str = str.substr(0, str.length - 2);
            if (item.goodsInfo.goodsInventory.length > 0) {
              item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
                if (str === goodsInventoryItems.keyC) {
                  goodsInventoryItems.valueC.forEach((valueCItem) => {
                    if (valueCItem.startQuantity === item.num || item.num > valueCItem.startQuantity) {
                      item.price = valueCItem.price;
                      item.total = (Number(item.price) * item.num).toFixed(2)
                    }
                  })
                }
              });
            }
          }
        });
        this.commodityTotal();
      }, 1000)
    },
    // 总价
    subtotal() {
      this.lists.forEach((item) => {
        item.goods.forEach((listItem) => {
          if (listItem.priceRanges.length == 1) {
            listItem.total = this.$fun.ceil(listItem.num * Number(listItem.price));
          } else if (listItem.priceRanges.length == 2) {
            if (listItem.num < listItem.priceRanges[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                  listItem.num * listItem.priceRanges[0].price
              );
              listItem.univalence = listItem.priceRanges[0].price;
            } else if (listItem.num >= listItem.priceRanges[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                  listItem.num * listItem.priceRanges[1].price
              );
              listItem.univalence = listItem.priceRanges[1].price;
            }
          } else if (listItem.priceRanges.length == 3) {
            if (listItem.num < listItem.priceRanges[1].startQuantity) {
              listItem.total = this.$fun.ceil(
                  listItem.num * listItem.priceRanges[0].price
              );
              listItem.univalence = listItem.priceRanges[0].price;
            } else if (
                listItem.num >= listItem.priceRanges[1].startQuantity &&
                listItem.num < listItem.priceRanges[2].startQuantity
            ) {
              listItem.total = this.$fun.ceil(
                  listItem.num * listItem.priceRanges[1].price
              );
              listItem.univalence = listItem.priceRanges[1].price;
            } else if (listItem.num >= listItem.priceRanges[2].startQuantity) {
              listItem.total = this.$fun.ceil(
                  listItem.num * listItem.priceRanges[2].price
              );
              listItem.univalence = listItem.priceRanges[2].price;
            }
          }

          if (listItem.univalence == 0) {
            listItem.total = this.$fun.ceil(listItem.num * Number(listItem.price));
          }
          //   item.total = this.$fun.ceil(listItem.num * Number(listItem.price));
        });
      });
    },
    // 单选
    oddChoice(i) {
      let flag = this.lists[i].goods.every((item) => item.checked == true);
      if (flag) {
        this.lists[i].checked = true;
      } else {
        this.lists[i].checked = false;
      }
      this.isCheckedAll();
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 店铺全选
    shopAll(i) {
      if (this.lists[i].checked) {
        this.lists[i].goods.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.lists[i].goods.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
      this.isCheckedAll();
      this.commodityTotal();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true;
          this.shopAll(index);
        });
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false;
          this.shopAll(index);
        });
      }
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0;
      this.isTotal = 0;
      this.shopTotal = 0;
      this.idArr = [];
      this.lists.forEach((item, i) => {
        if (item.goods.some((items) => items.checked)) {
          this.shopTotal += 1;
        }
        item.goods.forEach((listItem, j) => {
          if (listItem.checked) {
            this.totalPrice += listItem.total - 0;
            this.isTotal += listItem.num;
            this.idArr.push(listItem.id);
          }
          listItem.total = (listItem.price * listItem.num).toFixed(2)
        });
      });
    },
    // 删除
    delCart(id, shopIndex, goodsIndex) {
      let datas = {
        ids: "",
      };
      let msg = this.$fanyi("将该商品将移出购物车");
      if (id) {
        datas.ids = id;
      } else {
        if (this.idArr == false)
          return this.$message.error(this.$fanyi("未选择操作的商品"));
        datas.ids = this.idArr.join(",");
        msg = this.$fanyi("是否将选中的商品移除购物车");
      }
      this.$refs.dialog.open(
          // 文本,复杂的在组件里添加插槽
          msg,
          () => {
            // 确认按钮事件
            this.$api.goodsCartDelete(datas).then((res) => {
              if (res.code != 0) return;
              this.$message({
                type: "success",
                message: this.$fanyi("移除成功"),
              });
              if (id) {
                this.lists[shopIndex].goods.splice(goodsIndex, 1);
                if (this.lists[shopIndex].goods == 0) {
                  this.lists.splice(shopIndex, 1);
                }
              } else {
                // 将购物车选中的选项删除
                for (let i = 0; i < this.lists.length; i++) {
                  let shopItem = this.lists[i];
                  for (let j = 0; j < this.lists[i].goods.length; j++) {
                    let goodsItem = this.lists[i].goods[j];
                    if (this.idArr.indexOf(goodsItem.id) != -1) {
                      shopItem.goods.splice(j, 1);
                      j--;
                    }
                  }
                  if (shopItem.goods.length == 0) {
                    this.lists.splice(i, 1);
                    i--;
                  }
                }
              }
              // 获取总价
              this.commodityTotal();
              // 重新计算购物车商品数量
              this.$store.dispatch("goodsToCartNum");
              // }
            });
          },
          () => {
          }
      );
    },

    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == "") return false;
      let newDetailList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      if (item.goodsInfo != undefined) {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem, index) => {
            newDetailList.push({
              key: specificationItem.keyC,
              value: specificationItem.valueC[specificationItem.active].name
            })
            str += specificationItem.valueC[specificationItem.active].name + '㊖㊎'
          })
          str = str.substr(0, str.length - 2);
        }
        if (item.goodsInfo.goodsInventory.length > 0) {
          item.goodsInfo.goodsInventory.forEach((goodsInventoryItems) => {
            if (str === goodsInventoryItems.keyC) {
              sku_id = goodsInventoryItems.valueC[0].skuId;
              spec_id = goodsInventoryItems.valueC[0].specId;
            }
          });
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
      } else {
        sku_id = item.sku_id;
        spec_id = item.spec_id;
      }
      let datas = {
        id: item.id,
        num: item.num,
        price: item.price,
        detail: JSON.stringify(item.detail),
        client_remark: item.client_remark,
        pic: item.pic,
        sku_id: sku_id,
        spec_id: spec_id,
      };
      this.$api.goodsCartEdit(datas).then((res) => {
        if (res.code != 0) return false;
      });
    },
    // 结算
    jieSuan() {
      let newArr = this.ids.split(",");
      if (this.idArr.length + newArr.length > 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      let arr = [];
      this.lists.forEach((item) => {
        item.goods.forEach((items) => {
          if (items.checked === true) {
            arr.push(items)
          }
        })
      })
      this.$emit("cartAddGoodsToOrder", arr);
    },
    //鼠标移入
    mouseenter(index, listIndex) {
      this.lists[index].goods[listIndex].leftIcon = true;
      if (this.lists[index].goods[listIndex].goodsInfo == undefined) {
        this.uploadGoodsDetailsInfo(this.lists[index].goods[listIndex]);
      }
    },
    //鼠标移出
    mouseleave(index, listIndex) {
      this.lists[index].goods[listIndex].leftIcon = false;
    },
    //鼠标在元素上 按下
    mousedown(index, listIndex) {
      this.lists[index].goods[listIndex].showGoodsDetails = true;
    },
    uploadGoodsDetailsInfo(item) {
      this.$api.getGoodsAttribute({
        shop_type: item.from_platform,
        goods_id: item.goods_id
      }).then((res) => {
        if (res.data.length > 0) {
          item.goodsInfo = {
            goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
            specification: this.$fun.deepClone(res.data[0].specification),
          }
        } else {
          item.goodsInfo = {
            goodsInventory: [],
            specification: [],
          }
        }
        if (item.goodsInfo.goodsInventory.length > 0 && item.goodsInfo.specification.length > 0) {
          let detailStraight = ((detail) => {
            let str = [];
            detail.forEach((detailitem) => {
              str.push(detailitem.value);
            });
            //console.log(str);
            return str.join("㊖㊎");
          })(item.detail);
          for (let i = 0; i < item.goodsInfo.goodsInventory.length; i++) {
            for (let k = 0; k < item.goodsInfo.specification.length; k++) {
              item.detail.forEach((detailItem) => {
                for (let j = 0; j < item.goodsInfo.specification[k].valueC.length; j++) {
                  if (detailItem.key === item.goodsInfo.specification[k].keyC && detailItem.value === item.goodsInfo.specification[k].valueC[j].name) {
                    item.goodsInfo.specification[k].active = j;
                    item.goodsInfo.specification[k].oldActive = this.$fun.deepClone(j);
                    this.$forceUpdate();
                  }
                }
              })
            }
            if (item.goodsInfo.goodsInventory[i] != undefined && item.goodsInfo.goodsInventory[i].keyC === detailStraight) {
              item.goodsInfo.goodsInventory[i].valueC.forEach((valueCItem) => {
                if (item.num === valueCItem.startQuantity || item.num > valueCItem.startQuantity) {
                  item.price = valueCItem.price;
                }
              })
              break;
            }
          }
        }
      })
    }
  },
};
</script>

<style lang='scss' scoped>
@import "../../../../../../css/mixin";

.main {
  width: 1350px;
  margin: 10px auto 80px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.1);
  border-radius: 6px;
  padding: 20px 0 0;
  border: 1px solid #e8e8e8;

  /deep/ input[type="checkbox"]:checked {
    background: #4A91E9;
  }

  &.hei {
    margin-top: 20px;
    height: 650px;
    overflow: auto;
  }

  input {
    vertical-align: middle;
  }

  .type {
    display: flex;
    align-items: center;

    button {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
      background-color: transparent;
      line-height: 10px;
      letter-spacing: 0px;
      margin: 26px 0 26px 25px;
    }

    div {
      font-size: 20px;
      font-weight: 600;
      color: #B4272B;
    }
  }

  input {
    width: 16px;
    height: 16px;
  }

  .head {
    // top的设置在行内样式里
    height: 56px;
    margin: 0 20px;
    background: #eee;
    position: sticky;
    z-index: 4;

    ul {
      display: flex;
      line-height: 56px;
      font-size: 15px;
      font-weight: 600;

      .chexk-box {
        text-align: left;
        display: flex;
        padding-left: 10px;
        align-items: center;

        input {
          margin-right: 10px;
        }
      }

      .goods {
        flex: 0 0 240px;
      }


      li {
        flex: 1;
        text-align: center;

        input {
          margin-left: 10px;
        }

        &.detail {
          flex: 0 0 200px;
        }
      }
    }
  }

  .lists {
    margin: 0 20px;
    padding-bottom: 100px;
    height: 450px;
    overflow: scroll;
  }

  .item {
    h4 {
      font-weight: bold;
      font-size: 16px;
      line-height: 64px;
      display: flex;
      align-items: center;
      color: #333333;

      input {
        margin: 0 20px;
      }

      a {
        color: #333;

        &:hover {
          color: #999;
        }
      }

      img {
        margin-left: 5px;
        vertical-align: middle;
        float: none;
      }
    }

    .list {
      background: #F9F9F9;

      .fg {
        width: 1360px;
        height: 10px;
        background: transparent;
        margin: 0 auto;
      }

      ul:hover {
        background: #FAF2F2;
      }

      ul {
        height: 150px;
        display: flex;
        border-top: 1px solid #ededed;

        .checkBox {
          input {
            margin-left: 20px;
          }
        }

        .goods {
          width: 240px;
          min-width: 240px;
          padding-right: 26px;

          .text {
            height: 110px;
            display: flex;
            flex-direction: column;

            > input {
              margin-top: 34px;
              padding-left: 10px;
            }
          }
        }

        .price {
          width: 200px;

          p {
            width: 200px;
            word-break: break-all;
          }
        }

        li {
          // width: 14.28%;
          flex: 1;

          .check {
            height: 80px;
            display: flex;
            align-items: center;
          }

          input {
          }

          &:first-child {
            display: flex;
            margin-right: 10px;

            .img {
              float: left;
              width: 100px;
              height: 100px;
              margin-left: 15px;

              img {
                min-width: 100px;
                min-height: 100px;
                width: 100px;
                height: 100px;
              }
            }
          }

          &.detail {
            position: relative;
            //flex: 0 0 200px;
            width: 100px;

            height: 125px;
            min-width: 100px;
            box-sizing: border-box;
            border: 1px solid transparent;
          }

          &.selectDetail {
            border: 1px dashed #B4272B;
          }

          &.goods {
            position: relative;
            // > div {
            //   position: absolute;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   text-align: left;
            //   font-size: 14px;
            //   color: #000000;
            //   line-height: 19px;
            //
            // }
          }

          a {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .bei {
            width: 220px;
            min-height: 26px;
            height: 26px;
            border: 1px solid #DFDFDF;
            border-radius: 3px;
          }

          span {
            font-size: 14px;
            color: #B4272B;
            font-weight: 600;
          }
        }

        .del {
          font-size: 14px;
          color: #333333;

          &:hover {
            color: #B4272B;
          }
        }

        .price {
          .price-box {
            .danjia {
              text-align: left;
            }

            p {
              text-align: center;
              padding: 0 10px;
            }

            padding-left: 15px;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .cart {
    padding: 50px 0;
    text-align: center;
    overflow: hidden;

    > div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    img {
      margin: 0 auto;
    }

    .nav {
      margin: 50px 0 0 30px;

      p {
        font-size: 20px;
        line-height: 50px;
        text-align: center;

        a {
          color: $homePageSubjectColor;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    width: 1400px;
    height: 70px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(10, 11, 34, 0.05);
    border-radius: 10px 10px 0px 0px;
    position: sticky;
    bottom: -0px;
    left: 50%;
    // transform: translate(-50%);
    line-height: 50px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;

    input {
      margin-left: 40px;
      width: 16px;
      height: 16px;
      margin-right: 20px;
    }

    b {
      cursor: pointer;
      font-size: 14px;
      color: #333333;
      line-height: 19px;

      &.quanXuan {
        font-weight: 400;
      }

      &.del {
        font-size: 14px;
        font-weight: 400;
        color: $homePageSubjectColor;
        line-height: 19px;
        margin-left: 40px;
      }
    }

    .total {
      margin: 0 0 0 auto;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-left: 0px;

      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;

        .price {
          color: #B4272B;
          font-weight: bold;
          font-size: 20px;
          max-width: 246px;
          // flex-direction: column;

          span {
            font-size: 24px;
          }
        }

        .RMBTotal {
          font-weight: 400;
          font-size: 14px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 700;
        // margin: 0 10px;
      }

      .goodsNum {
        margin: 0 0 0 250px;
        position: relative;
        line-height: 30px;
        display: flex;
        font-size: 14px;
        align-items: center;

        .goodsNumLeft, .goodsNumRight {
          font-size: 16px;
          font-weight: 400 !important;
          color: #333;
        }

        .goodsNumCenter {
          margin-right: 10px;
          font-size: 16px;
          font-weight: bold;
          color: #B4272B;
        }
      }
    }

    i {
      margin: 0 20px;
    }

    button {
      width: 200px;
      height: 70px;
      background: #B4272B;
      border-radius: 0 10px 0 0;
      font-size: 30px;
      font-weight: 400;
      color: #fff;
      line-height: 70px;
      /*   */
      cursor: not-allowed;
      text-align: center;
      margin-left: 68px;
    }

    .btn {
      cursor: pointer;
    }
  }

  .order {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  width: 320px;

  .goodsDetailAllOneBox {
    display: flex;
    margin-bottom: 10px;

    h1 {
      font-size: 16px;
      font-weight: bold;
      padding-top: 3px;
      margin-right: 16px;
      min-width: 40px;
    }

    .valueCBoxList {
      display: flex;
      flex-wrap: wrap;
      border: none;

      div {
        background: transparent;
        border: 1px solid #DFDFDF;
        padding: 5px 10px;
        font-size: 14px;
        margin-right: 16px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .active {
        color: #B4272B;
        border: 1px solid #B4272B;
      }
    }
  }

  .updateDetailOption {
    display: flex;
    align-items: center;
    margin-left: 65px;

    div {
      padding: 6px 16px;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    div:first-child {
      background: #B4272B;
      color: #fff;
      padding: 7px 16px;
      margin-right: 16px;
    }

    div:last-child {
      border: 1px solid #B4272B;
      color: #B4272B;
    }
  }
}

.DetailAll {
  //position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  max-height: 90%;
  // border: solid;
  width: 100%;
  padding-top: 26px;
  padding-left: 14px;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: center;

  p {
    margin: 0 auto;
    // width: 190px;
    text-align: left;
  }

  .leftIcon, .checkLeftIcon {
    width: 60px;
    height: 26px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
  }

  .checkLeftIcon {
    background-color: #B4272B;
  }

  .leftIcon {
    background-color: #DFDFDF;
  }
}

.detail:hover {
  border: 1px dashed #DFDFDF !important;
}
</style>
